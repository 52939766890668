import { useState } from 'react';
import { Link } from 'react-router-dom';
import Turnstile from "react-turnstile";
import { useToast } from '../utils/providers/toast_provider';

const ContactFormSection = () => {
  const { throwToast } = useToast();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    services: '',
    extra_info: '',
    captcha_response: '',
    accept_privacy: false,
  });

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value });
  };

  // Adjusted to handle the CAPTCHA token correctly
  const handleCaptchaChange = (token) => {
    setFormData({...formData, captcha_response: token });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.accept_privacy) {
      alert();
      throwToast({type: 'Error', message: 'You must accept the privacy policy to submit this form.', timeout: 2000})
      return;
    }

    throwToast({type: 'Info', message: 'Form submitted', timeout: 2000})

    const response = await fetch('/api/v1/forms', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
    if (response.ok) {
      throwToast({type: 'Success', message: 'Form submitted successfully', timeout: 2000})
    } else {
      throwToast({type: 'Error', message: 'Failed to submit consultation request.', timeout: 2000})
    }
  };

  return (
    <section id="contact" className="bg-gray-700 py-16 text-white">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-semibold mb-4">Contact Us</h2>
        <p className="mb-8">Ready to elevate your business with our software solutions? Reach out to schedule a consultation.</p>
        <form onSubmit={handleSubmit} className="space-y-4 px-4 md:px-16 lg:px-24 text-gray-900 text-left">
          <div className='flex gap-x-4 justify-center'>
            <div className='flex flex-col gap-2 flex-grow'>
              <label htmlFor="name" className="text-gray-300">Your Name</label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className='flex flex-col gap-2 flex-grow'>
              <label htmlFor="email" className="text-gray-300">Your Email</label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className='flex flex-col gap-2'>
            <label htmlFor="services" className="text-gray-300">What services do you require?</label>
            <textarea
              name="services"
              id="services"
              placeholder="What services do you require?"
              value={formData.services}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className='flex flex-col gap-2'>
            <label htmlFor="extra_info" className="text-gray-300">Can you give us more information?</label>
            <textarea
              name="extra_info"
              id="extra_info"
              placeholder="Can you give us more information? For example business info, what the website / service will be used for?"
              value={formData.extra_info}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className='flex gap-2 justify-center items-center text-lg'>
            <label htmlFor="accept_privacy" className="text-gray-300">
              By checking this box, you have read and accepted the <Link to="/privacy-policy" className="font-bold text-red-300 hover:text-red-400 hover:underline">Privacy Policy</Link>.
            </label>
            <input
              type="checkbox"
              name="accept_privacy"
              id="accept_privacy"
              value={formData.accept_privacy}
              onChange={(e) => setFormData({...formData, accept_privacy: e.target.checked})}
              required
              className="border border-gray-300 rounded-md h-6 w-6"
            />
          </div>
          <Turnstile
            className="dave"
            sitekey={window.cloudflareKey}
            onVerify={handleCaptchaChange}
          />
          <input
            type="hidden"
            name="captchaResponse"
            value={formData.captchaResponse}
          />
          <div className='flex justify-center pt-4'>
            <button type="submit" className="bg-gradient-to-b from-red-500 from-60% to-red-600 text-white text-xl hover:from-red-700 hover:to-red-800 py-2 px-6 rounded-full text-lg transition duration-300 ease-in-out">
              Schedule a consultation
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactFormSection;
