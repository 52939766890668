import ServiceCard from "./service_card";
import { t } from "../utils/i18n";
import { WebsiteHosting, SoftwareDesign, ContentManagement, SeoManagement, EmailHosting, MobileAppDevelopment } from './service_icons';

const Services = () => {
  return (
    <section id="services" className="bg-gray-700">
      <div className="py-24 container mx-auto text-center flex flex-col gap-12">
        <div>
          <h2 className="text-4xl font-semibold mb-8 text-white">{t('home.services.title')}</h2>
          <h3 className="text-xl font-semibold my-4 text-white">{t('home.services.sub-title')}</h3>
        </div>
        <div className="flex-grow">
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12'>
            <ServiceCard
              title={t('home.services.web-hosting.title')}
              text={t('home.services.web-hosting.text')}
              svgComponent={<WebsiteHosting />}
            />
            <ServiceCard 
              title={t('home.services.software-design.title')}
              text={t('home.services.software-design.text')}
              svgComponent={<SoftwareDesign />}
            />
            <ServiceCard 
              title={t('home.services.content-management.title')}
              text={t('home.services.content-management.text')}
              svgComponent={<ContentManagement />}
            />
            <ServiceCard
              title={t('home.services.seo-management.title')}
              text={t('home.services.seo-management.text')}
              svgComponent={<SeoManagement />}
            />
            <ServiceCard 
              title={t('home.services.email-hosting.title')}
              text={t('home.services.email-hosting.text')}
              svgComponent={<EmailHosting />}
            />
            <ServiceCard 
              title={t('home.services.mobile-app-development.title')}
              text={t('home.services.mobile-app-development.text')}
              svgComponent={<MobileAppDevelopment />}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services;
