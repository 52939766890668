import { HeroSection, FeaturesSection, ReviewsSection, ContactFormSection, PortolioSection, Services } from "../components";

const App = () => {
  return (
    <div className="font-sans bg-gradient-to-r from-gray-900 to-gray-950">
      <div className='min-h-screen flex flex-col'>
        <HeroSection />
      </div>
        <Services />
      
      
      {false && <PortolioSection />}
      <div className='min-h-screen flex flex-col'>
      <FeaturesSection />
      
      {/* Add other sections here */}
      
      {false && <ReviewsSection />}
      <ContactFormSection />
      
      
      </div>
    </div>
  );
}

export default App;