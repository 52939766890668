import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainLayout from "./layouts/main_layout";
import HomePage from './pages/home';
import PrivacyPolicyPage from './components/privacy_policy';
import ToastProvider from './utils/providers/toast_provider';
//import TermsAndConditionsPage from './components/terms_and_conditions';

const App = () => {
  return (
    <Router>
      <MainLayout>
        <ToastProvider >
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            {//<Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
            }

          </Routes>
        </ToastProvider>
      </MainLayout>
    </Router>
  );
}

export default App;