import { t } from "../utils/i18n";
import ServiceCard from "./service_card";

import { CustomSoftware, CuttingEdgeTech, DDoSProtection, DedicatedServers, MaintenanceServices, Support } from "./feature_icons";

const FeaturesSection = () => {
  return (
    <section id="features" className="">
      <div className="py-24 container mx-auto text-center flex flex-col gap-12">
        <div>
          <h2 className="text-4xl font-semibold mb-8 text-white">{t('home.features.title')}</h2>
          <h3 className="text-xl font-semibold my-4 text-white">{t('home.features.sub-title')}</h3>
        </div>
        <div className="flex-grow">
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12'>
            <ServiceCard
              title={t('home.features.cutting-edge-technologies.title')}
              text={t('home.features.cutting-edge-technologies.text')}
              svgComponent={<CuttingEdgeTech />}
            />
            <ServiceCard 
              title={t('home.features.dedicated-servers.title')}
              text={t('home.features.dedicated-servers.text')}
              svgComponent={<DedicatedServers />}
            />
            <ServiceCard 
              title={t('home.features.ddos-protection.title')}
              text={t('home.features.ddos-protection.text')}
              svgComponent={<DDoSProtection />}
            />
            <ServiceCard
              title={t('home.features.support.title')}
              text={t('home.features.support.text')}
              svgComponent={<Support />}
            />
            <ServiceCard 
              title={t('home.features.maintenance-services.title')}
              text={t('home.features.maintenance-services.text')}
              svgComponent={<MaintenanceServices />}
            />
            <ServiceCard 
              title={t('home.features.custom-software.title')}
              text={t('home.features.custom-software.text')}
              svgComponent={<CustomSoftware />}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;