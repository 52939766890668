import { Header, NavBar, Footer } from '../components'
import { useState } from 'react';
import ReactDOM from 'react-dom';
import FavIcon from '../assets/favicon.ico';

const Favicon = () => {
  return ReactDOM.createPortal(
    <>
      <link rel="icon" href={FavIcon} />
      <link rel="apple-touch-icon" href={FavIcon} />
    </>,
    document.head
  );
};

const MainLayout = ({children}) => {
  const [navIsOpen, setNavIsOpen] = useState(false)
  return (
    <div className="font-sans bg-gradient-to-r from-gray-900 to-gray-950 min-h-screen">
      <Favicon />
      <NavBar 
        navIsOpen={navIsOpen}
      />
      <Header 
        navIsOpen={navIsOpen}
        setNavIsOpen={setNavIsOpen}
      />
      {children}
      <Footer />
    </div>
  )
}

export default MainLayout