const PrivacyPolicy = () => (
  <main className="container mx-auto px-4 py-32 text-gray-100">
        <h1 className="text-3xl font-bold mb-4">Helix Network Ltd Customer Privacy Notice</h1>
        <p className="mb-4">This privacy notice tells you what to expect us to do with your personal information.</p>
        <h2 className="text-2xl font-semibold mb-2">Our contact details</h2>
        <p>Email: <a href="mailto:accounts@helixnet.co" className="font-bold text-red-500 hover:text-red-600 hover:underline">accounts@helixnet.co</a></p>
        <h2 className="text-2xl font-semibold mb-2">What information we collect, use, and why</h2>
        <p>We collect or use the following information to receive customer inquiries:</p>
        <ul className="list-disc pl-5 mb-4">
            <li>Names and contact details</li>
            <li>Information about a customer's business/use of our services</li>
        </ul>
        <h2 className="text-2xl font-semibold mb-2">Lawful bases</h2>
        <p>Our lawful bases for collecting or using personal information for receiving customer inquiries are:</p>
        <ul className="list-disc pl-5 mb-4">
            <li>Consent</li>
            <li>Where we get personal information from</li>
            <li>People directly</li>
        </ul>
        <h2 className="text-2xl font-semibold mb-2">How long we keep information</h2>
        <p>[Paste your retention schedule here.]</p>
        <h2 className="text-2xl font-semibold mb-2">Sharing information outside the UK</h2>
        <p>Where necessary, we may transfer personal information outside of the UK. When doing so, we comply with the UK GDPR, making sure appropriate safeguards are in place. Please contact us for more information.</p>
        <h2 className="text-2xl font-semibold mb-2">Your data protection rights</h2>
        <p>Under data protection law, you have rights including:</p>
        <ul className="list-disc pl-5 mb-4">
            <li>Your right of access - You have the right to ask us for copies of your personal data.</li>
            <li>Your right to rectification - You have the right to ask us to rectify personal data you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.</li>
            <li>Your right to erasure - You have the right to ask us to erase your personal data in certain circumstances.</li>
            <li>Your right to restriction of processing - You have the right to ask us to restrict the processing of your personal data in certain circumstances.</li>
            <li>Your right to object to processing - You have the right to object to the processing of your personal data in certain circumstances.</li>
            <li>Your right to data portability - You have the right to ask that we transfer the personal data you gave us to another organisation, or to you, in certain circumstances.</li>
            <li>Your right to withdraw consent – When we use consent as our lawful basis you have the right to withdraw your consent.</li>
        </ul>
        <h2 className="text-2xl font-semibold mb-2">How to complain</h2>
        <p>If you have any concerns about our use of your personal data, you can make a complaint to us using the contact details at the top of this privacy notice. If you remain unhappy with how we’ve used your data after raising a complaint with us, you can also complain to the ICO.</p>
        <p>The ICO’s address: <br />
        Information Commissioner’s Office<br />
        Wycliffe House<br />
        Water Lane<br />
        Wilmslow<br />
        Cheshire<br />
        SK9 5AF<br />
        Helpline number: 0303 123 1113<br />
        Website: <a href="https://www.ico.org.uk/make-a-complaint" className="font-bold text-red-500 hover:text-red-600 hover:underline">https://www.ico.org.uk/make-a-complaint</a></p>
        <h2 className="text-2xl font-semibold mb-2">Last updated</h2>
        <p>9 May 2024</p>
  </main>
)

export default PrivacyPolicy;
