const Sram = {
  'home': {
    'services': {
      'title': 'Services we offer',
      'sub-title': `We provide all of the below Services. Can't find what you are looking for? Feel free to get in contact and we still may be of assistance`,
      'web-hosting': {
        'title': 'Website Hosting',
        'text': `We offer high-performance hosting on our dedicated servers. Whether it's a small portfolio or a full-blown application, we provide a platform with support and maintenance.`,
      },
      'software-design': {
        'title': 'Software Design',
        'text': `Our development team provides full development of websites, mobile apps, or desktop applications. We guide your project through the entire development stage, including support and maintenance after launch.`,
      },
      'content-management': {
        'title': 'Content Management',
        'text': `Our team handles content management for your platform, including products, social media, and general on-page content. We provide a full range of skills to make your business succeed.`,
      },
      'seo-management': {
        'title': 'SEO Management',
        'text': `We offer comprehensive SEO services to improve your website's visibility on search engines, driving more organic traffic to your site.`,
      },
      'email-hosting': {
        'title': 'Email Hosting',
        'text': `Our email hosting services provide a professional email solution for your business, with features like custom domain emails and robust security.`,
      },
      'mobile-app-development': {
        'title': 'Mobile App Development',
        'text': `We specialize in developing mobile apps for both iOS and Android platforms, ensuring your business reaches your customers on the go.`,
      }
    },
    'features': {
      'title': 'Why choose us?',
      'sub-title': `There are many other providers. Here are reasons why you should choose us`,
      'cutting-edge-technologies': {
        'title': 'Cutting-Edge Technologies',
        'text': `We leverage the latest technologies, ensuring your software stands out in today's competitive landscape.`,
      },
      'dedicated-servers': {
        'title': 'Dedicated Servers',
        'text': `Your applications run on our dedicated servers, guaranteeing reliability, security, and top-notch performance.`,
      },
      'ddos-protection': {
        'title': 'DDoS Protection',
        'text': `We prioritize security with robust DDoS protection to keep your systems safe from online threats.`,
      },
      'support': {
        'title': '24/7 Support',
        'text': `Our dedicated support team is available 24/7 to address any issues, ensuring your peace of mind.`,
      },
      'maintenance-services': {
        'title': 'Maintenance Services',
        'text': `Choose our maintenance services for regular updates and enhancements, keeping your software at its best.`,
      },
      'custom-software': {
        'title': 'Custom Software',
        'text': `We specialize in crafting fully custom software tailored to your needs, eliminating unnecessary bloatware.`,
      },
    }
  }
}

export default Sram;
