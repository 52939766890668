import React, { useState, useEffect, useRef, createContext, useContext } from 'react';

const ToastContext = createContext();

export const useToast = () => {
  const context = useContext(ToastContext)
  if (!context) {
    throw new Error('useToast must be used within ToastProvider')
  }
  return context;
}

const ToastProvider = ({ children }) => {
  const [toastQueue, setToastQueue] = useState([]);
  const [currentToastIndex, setCurrentToastIndex] = useState(0);
  const toastRef = useRef(null);
  const timers = useRef({}); // Ref to store timers

  const throwToast = (toastData) => {
    setToastQueue((prevQueue) => [...prevQueue, toastData]);
    // Call the callback to set up timers for the new toast
    setupToastTimers(toastData);
  };

  const setupToastTimers = (toastData) => {
    // Clear existing timers
    Object.values(timers.current).forEach(clearTimeout);
    timers.current = {};

    // Add the toast after   0.5 seconds
    timers.current.slideIn = setTimeout(() => {
      if (toastRef.current) {
        toastRef.current.classList.add('toast-active');
      }
    },   500);

    // Remove the toast after the timeout duration plus   0.5 seconds
    timers.current.slideOut = setTimeout(() => {
      if (toastRef.current) {
        toastRef.current.classList.remove('toast-active');
      }
    }, toastData.timeout +   500);

    // Remove the toast from the queue after the timeout duration plus   1 second
    timers.current.removeToast = setTimeout(() => {
      setCurrentToastIndex((prevIndex) => prevIndex +   1);
    }, toastData.timeout +   1000);
  };

  useEffect(() => {
    if (toastQueue.length >   0 && currentToastIndex < toastQueue.length) {
      // Set up timers for the current toast
      setupToastTimers(toastQueue[currentToastIndex]);
    }
  }, [toastQueue, currentToastIndex]);

  const getToastColor = (type) => {
    switch (type) {
      case 'Success':
        return 'bg-green-500';
      case 'Error':
        return 'bg-red-500';
      case 'Warning':
        return 'bg-orange-500';
      case 'Info':
        return 'bg-gray-500';
      default:
        return 'bg-gray-500';
    }
  };

  return (
    <ToastContext.Provider value={{ throwToast }}>
      {children}
      {toastQueue.length >   0 && currentToastIndex < toastQueue.length && (
        <div className='flex justify-center'>
          <div
            ref={toastRef}
            className={`fixed bottom-0 md:right-0 m-4 p-4 text-2xl text-white text-center rounded shadow-lg z-40 ${getToastColor(toastQueue[currentToastIndex].type)} toast`}
          >
            {toastQueue[currentToastIndex].message}
        </div>
        </div>
      )}
    </ToastContext.Provider>
  );
};

export default ToastProvider;