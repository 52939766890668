import SvgWrapper from "./service_icons/svg_wrapper";

const ServiceCard = ({svgComponent, title, text}) => (
  <div className="bg-gray-100 rounded-lg shadow-xl p-6 text-gray-900">
    <SvgWrapper className={'h-24 w-24 text-red-600 mb-4 mx-auto'}>
      {svgComponent}
    </SvgWrapper>
    <h3 className="text-2xl font-semibold my-4">{title}</h3>
    <p>
      {text}
    </p>
  </div>
);

export default ServiceCard;
